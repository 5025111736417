<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex">
        <div class="w-100 d-lg-flex justify-content-center">
          <div id="login-intro" style="flex: 1 1 0%; box-sizing: border-box;">
            <div class="logo">
              <img class="ng-tns-c25-5" src="/assets/shield-white.svg">
            </div>
            <div class="title ng-trigger ng-trigger-animate">
              Register for <span class="ng-tns-c25-5" style="font-family: boxen-bold;">Secris</span>
            </div>
            <div class="description ng-trigger ng-trigger-animate">
              Already have an account? <router-link to="/auth/login">Click here</router-link> to log in.
            </div>
          </div>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            CREATE AN ACCOUNT 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please register an account below
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="register-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- email -->
              <b-form-group
                label="Name"
                label-for="register-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    id="register-name"
                    v-model="userName"
                    :state="errors.length > 0 ? false:null"
                    name="register-name"
                    placeholder="Your Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Organisation Name"
                label-for="register-organisation_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Organisation Name"
                  rules="required"
                >
                  <b-form-input
                    id="register-organisation_name"
                    v-model="organisationName"
                    :state="errors.length > 0 ? false:null"
                    name="register-organisation_name"
                    placeholder="Organisation Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-alert v-model="showRegisterErrors" variant="danger" dismissible>
                <div class="alert-body">
                  <span>{{ registerError }}</span>
                </div>
              </b-alert>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Register
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>
              Already have an account?  <b-link :to="{name:'auth-login'}"><small>Login</small></b-link>
            </span>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BCardText, BCardTitle, BForm, BButton, BAlert,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import auth from '@/auth/auth'

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      userEmail: '',
      userName: '',
      registerError: '',
      organisationName: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    showRegisterErrors() {
      return this.registerError !== ''
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.registerValidation.validate().then(success => {
        if (success) {
          auth.register({
            email: this.userEmail,
            name: this.userName,
            organisation_name: this.organisationName,
          })
              .then(response => {
                // Make sure they have some permissions
                if (response.status !== 200) {
                  this.registerError = 'We were unable to register you. Please contact support.'
                }

                if (response.status === 200) {
                  this.$router.replace('/auth/registered')
                }
              }, error => {
                if (error.response) {
                  this.registerError = error.response.data.error
                } else {
                  this.registerError = error.message
                }
              })
        }
      })
    },
  },
}
</script>
